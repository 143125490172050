
/**
 * Date.UTC(year, month, day, hour, minute)

year = full year.
month = an integer between 0 (January) and 11 (December)
day = an integer between 1 and 31
hour = an integer between 0 and 23
minute = an integer between 0 and 59

ATTENZIONE LA DATA DEVE ESSERE IMMESSA IN MODALITA' UTC ALTRIMENTI CI POSSONO ESSERE ERRORI PER GLI UTENTI
*/



window.WebinarStartDateTime = new Date(Date.UTC(2021, 9, 25, 19,00))

