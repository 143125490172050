//:: Libreria di elementi js comuni a tutte le pagine

import "./webinar_date"

import "@camtv/front-base-css/main"
import "./footer"
import "./functions"

import CtvLegalDialog from "@camtv/front-base-ui/lit/legal-dialog"

window.addEventListener('DOMContentLoaded', (event) => {

	document.querySelectorAll(".COOKIE").forEach((el) => el.onclick = (evt) => { 
		evt.preventDefault(); CtvLegalDialog.ShowCookies("https://linemed-static.web.app/legal/cookies.md") 
	})
	document.querySelectorAll(".PRIVACY").forEach((el) => el.onclick = (evt) => { 
		evt.preventDefault(); CtvLegalDialog.ShowPrivacy("https://linemed-static.web.app/legal/privacy.md") 
	})
	    
});