
import axios from 'axios'
import Validate from '@camtv/front-base-lib/utilities/validate'
import { html, render } from 'lit-html';
import CtvVideo from '@camtv/front-base-ui/lit/video2'
import CtvDialogModal from '@camtv/front-base-ui/lit/dialog-modal'
import CtvDialogPopup from '@camtv/front-base-ui/lit/dialog-popup'
import BrowsersWhitelist from '@camtv/front-base-lib/utilities/browser-whitelist'
import Utilities from '@camtv/front-base-lib/utilities/utilities'

export default class SubscribeForm extends CtvDialogModal {

	static get DomTag() { return  'ctv-dialog-subscribe-form'; }

	constructor() {
		super();

		this.show_back_link = true
		this.form = null


		this.SetLeftLink(null)
		this.SetRightLink(null)
		this.SetButton(null)
	}

	Init() {
		SubscribeForm.InitSubscribeForm(this.querySelector("form")).then(()=>{ this.Ok() })

	}

	RenderFooter() {
		return html``;
	}

	async Show() {
		let el = document.createElement("div")
		let node = this.form
		let rnd = Utilities.GetRandomStringStartingWithLetter(10)
		node.querySelectorAll("label").forEach((el)=> {
			let f = el.getAttribute("for")
			if (node.querySelector("#"+f) != null) {
				node.querySelector("#"+f).setAttribute("id",rnd+f)
				el.setAttribute("for",rnd+f)
			}
		})
		
		el.append(node)
		this.body = this.ToHtml(el.innerHTML)

		return super.Show()
	}

	static async Show(form) {
		let dlg = new SubscribeForm()
		dlg.form = form.cloneNode(true)
		await dlg.Show()
	}

	static InitSubscribeForm(form) {	
		let btn = form.querySelector("button")
		let vFields = form.querySelectorAll("[data-field]")
		vFields.forEach((el)=> { el.addEventListener("focus",(evt)=>{ 
			vFields.forEach((fld)=> {
				if (fld.closest(".ctv-input-field") != null)
					fld.closest(".ctv-input-field").classList.remove("error")
			})
		})})

		if (form.querySelectorAll(".COOKIE") != null)
			form.querySelectorAll(".COOKIE").forEach((el) => el.onclick = (evt) => { 
				evt.preventDefault(); CtvLegalDialog.ShowCookies("https://linemed-static.web.app/legal/cookies.md") 
			})
		if (form.querySelectorAll(".PRIVACY") != null)			
			form.querySelectorAll(".PRIVACY").forEach((el) => el.onclick = (evt) => { 
				evt.preventDefault(); CtvLegalDialog.ShowPrivacy("https://linemed-static.web.app/legal/privacy.md") 
			})
	
		return new Promise((resolve, reject) => {
			form.onsubmit = async (evt) => {
				evt.preventDefault()		
	
				let verify_ok = Validate.ValidateForm(form,false,true,(fld, err)=>{
					let cif = fld.closest(".ctv-input-field")
					if (cif == null)
						return
					cif.querySelector(".error").innerText = err
					cif.classList.add("error")
				})
	
				if (verify_ok != true)
					return
	
				const data = {}
				vFields.forEach((el)=> {
					let el_val = el.value.trim() 
					if (el.type == "checkbox")
						el_val = el.getAttribute("checked")
					else
						data[el.getAttribute("data-field")] = el_val
				})
	
				try  {
					vFields.forEach((el)=> { el.setAttribute("readonly","true")})
					btn.classList.add("loading")
					let respo = await axios.post(form.getAttribute("data-submit-api-url"),data)
					if (respo.data.toLowerCase() != "accepted")
						throw("response.ok != true")
									
					document.location.href = form.getAttribute("data-submit-redirect-url")
	
					setTimeout(()=>{
						resolve()
					},1000)
	
					return 
				}
				catch (Ex) {
					console.log(Ex)
					
					await CtvDialogPopup.ShowMessageBox("Errore","La richiesta di iscrizione non è andata a buon fine. Riprovare tra qualche secondo.")
					reject()
				}
				btn.classList.remove("loading")
				vFields.forEach((el)=> { el.removeAttribute("readonly")})
	
				
			}	
		})
	}
}

SubscribeForm.RegisterElement()