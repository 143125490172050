import "../../js/common"
import SubscribeForm from "../../js/subscribe_form"

window.addEventListener('DOMContentLoaded', (event) => {
	
	SubscribeForm.InitSubscribeForm(document.querySelector(".SUBSCRIBE_FORM"))

	document.querySelectorAll(".REGISTER").forEach((el)=> { 
		el.onclick = ()=> { SubscribeForm.Show(document.querySelector(".SUBSCRIBE_FORM")) }
	})

});


